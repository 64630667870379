import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';

import {VgOverlayPlay} from './vg-overlay-play';


export class VgOverlayPlayModule {static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [ CommonModule ],
    declarations: [
        VgOverlayPlay
    ],
    exports: [
        VgOverlayPlay
    ]
}, ] },
];
/** @nocollapse */
static ctorParameters: ({type: any, decorators?: DecoratorInvocation[]}|null)[] = [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
