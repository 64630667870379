import {
    Component, ElementRef, Input, HostListener, OnInit, ViewEncapsulation, HostBinding,
    OnDestroy
} from '@angular/core';
import { VgAPI } from '../../core/services/vg-api';
import { VgControlsHidden } from './../../core/services/vg-controls-hidden';
import { VgStates } from '../../core/states/vg-states';
import { Subscription } from 'rxjs/Subscription';


export class VgScrubBar implements OnInit, OnDestroy {
     hideScrubBar: boolean = false;

     vgFor: string;
     vgSlider: boolean = true;

    elem: HTMLElement;
    target: any;
    isSeeking: boolean = false;
    wasPlaying: boolean = false;

    subscriptions: Subscription[] = [];

    constructor(ref: ElementRef, public API: VgAPI, vgControlsHiddenState: VgControlsHidden) {
        this.elem = ref.nativeElement;
        this.subscriptions.push(vgControlsHiddenState.isHidden.subscribe(hide => this.onHideScrubBar(hide)));
    }

    ngOnInit() {
        if (this.API.isPlayerReady) {
            this.onPlayerReady();
        }
        else {
            this.subscriptions.push(this.API.playerReadyEvent.subscribe(() => this.onPlayerReady()));
        }
    }

    onPlayerReady() {
        this.target = this.API.getMediaById(this.vgFor);
    }

    protected seekStart() {
        if (this.target.canPlay) {
            this.isSeeking = true;
            if (this.target.state === VgStates.VG_PLAYING) {
                this.wasPlaying = true;
            }
            this.target.pause();
        }
    }

    protected seekMove(offset: number) {
        if (this.isSeeking) {
            let percentage = Math.max(Math.min(offset * 100 / this.elem.scrollWidth, 99.9), 0);
            this.target.time.current = percentage * this.target.time.total / 100;
            this.target.seekTime(percentage, true);
        }
    }

    protected seekEnd(offset: number) {
        this.isSeeking = false;
        if (this.target.canPlay) {
            let percentage = Math.max(Math.min(offset * 100 / this.elem.scrollWidth, 99.9), 0);
            this.target.seekTime(percentage, true);
            if (this.wasPlaying) {
                this.wasPlaying = false;
                this.target.play();
            }
        }
    }

    protected touchEnd() {
        this.isSeeking = false;
        if (this.wasPlaying) {
            this.wasPlaying = false;
            this.target.play();
        }
    }

    protected getTouchOffset(event: any) {
        let offsetLeft: number = 0;
        let element: any = event.target;
        while (element) {
            offsetLeft += element.offsetLeft;
            element = element.offsetParent;
        }
        return event.touches[ 0 ].pageX - offsetLeft;
    }

    
    onMouseDownScrubBar($event: any) {
        if (!this.target.isLive) {
            if (!this.vgSlider) {
                this.seekEnd($event.offsetX);
            }
            else {
                this.seekStart();
            }
        }
    }

    
    onMouseMoveScrubBar($event: any) {
        if (!this.target.isLive && this.vgSlider && this.isSeeking) {
            this.seekMove($event.offsetX);
        }
    }

    
    onMouseOutScrubBar($event: any) {
        if (!this.target.isLive && this.vgSlider && this.isSeeking) {
            this.seekEnd($event.offsetX);
        }
    }

    
    onMouseUpScrubBar($event: any) {
        if (!this.target.isLive && this.vgSlider) {
            this.seekEnd($event.offsetX);
        }
    }

    
    onTouchStartScrubBar($event: any) {
        if (!this.target.isLive) {
            if (!this.vgSlider) {
                this.seekEnd(this.getTouchOffset($event));
            }
            else {
                this.seekStart();
            }
        }
    }

    
    onTouchMoveScrubBar($event: any) {
        if (!this.target.isLive && this.vgSlider && this.isSeeking) {
            this.seekMove(this.getTouchOffset($event));
        }
    }

    
    onTouchCancelScrubBar($event: any) {
        if (!this.target.isLive && this.vgSlider) {
            this.touchEnd();
        }
    }

    
    onTouchEndScrubBar($event: any) {
        if (!this.target.isLive && this.vgSlider) {
            this.touchEnd();
        }
    }

    
    onTouchLeaveScrubBar($event: any) {
        if (!this.target.isLive && this.vgSlider) {
            this.touchEnd();
        }
    }

    onHideScrubBar(hide: boolean) {
        this.hideScrubBar = hide;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'vg-scrub-bar',
    encapsulation: ViewEncapsulation.None,
    template: `<ng-content></ng-content>`,
    styles: [ `
        vg-scrub-bar {
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 50px;
            margin: 0;
            cursor: pointer;
            align-items: center;
            background: rgba(0, 0, 0, 0.75);
            z-index: 250;
            -webkit-transition: bottom 1s, opacity 0.5s;
            -khtml-transition: bottom 1s, opacity 0.5s;
            -moz-transition: bottom 1s, opacity 0.5s;
            -ms-transition: bottom 1s, opacity 0.5s;
            transition: bottom 1s, opacity 0.5s;
        }

        vg-controls vg-scrub-bar {
            position: relative;
            bottom: 0;
            background: transparent;
            height: 50px;
            flex-grow: 1;
            flex-basis: 0;
            margin: 0 10px;
            -webkit-transition: initial;
            -khtml-transition: initial;
            -moz-transition: initial;
            -ms-transition: initial;
            transition: initial;
        }

        vg-scrub-bar.hide {
            bottom: 0;
            opacity: 0;
        }

        vg-controls vg-scrub-bar.hide {
            bottom: initial;
            opacity: initial;
        }
    ` ]
}, ] },
];
/** @nocollapse */
static ctorParameters: ({type: any, decorators?: DecoratorInvocation[]}|null)[] = [
{type: ElementRef, },
{type: VgAPI, },
{type: VgControlsHidden, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'hideScrubBar': [{ type: HostBinding, args: ['class.hide', ] },],
'vgFor': [{ type: Input },],
'vgSlider': [{ type: Input },],
'onMouseDownScrubBar': [{ type: HostListener, args: ['mousedown', [ '$event' ], ] },],
'onMouseMoveScrubBar': [{ type: HostListener, args: ['mousemove', [ '$event' ], ] },],
'onMouseOutScrubBar': [{ type: HostListener, args: ['mouseout', [ '$event' ], ] },],
'onMouseUpScrubBar': [{ type: HostListener, args: ['mouseup', [ '$event' ], ] },],
'onTouchStartScrubBar': [{ type: HostListener, args: ['touchstart', [ '$event' ], ] },],
'onTouchMoveScrubBar': [{ type: HostListener, args: ['touchmove', [ '$event' ], ] },],
'onTouchCancelScrubBar': [{ type: HostListener, args: ['touchcancel', [ '$event' ], ] },],
'onTouchEndScrubBar': [{ type: HostListener, args: ['touchend', [ '$event' ], ] },],
'onTouchLeaveScrubBar': [{ type: HostListener, args: ['touchleave', [ '$event' ], ] },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
